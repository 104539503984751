@charset "utf-8";

@use "breakpoint";
@use "variables";
@use "sass:math";

// @include clearfix;
@mixin clearfix {
  &::after {
    display: block;
    clear: both;
    content: "";
  }
}

// 横幅 px → % 変換
// @include widthPercent(子要素, 親要素);
@mixin widthPercent($pxWidth, $parentPxWidth) {
  width: floor($pxWidth / $parentPxWidth * 100%);
}

// font-size可変 SP
// @include fzVariableSP(px);
@mixin fzVariableSP($fz) {
  font-size: math.div($fz, 375 /*SPデザイン横幅*/) * 100vw;
}

// font-size可変 PC
// @include fzVariablePC(px);
@mixin fzVariablePC($fz) {
  font-size: math.div($fz / 1200 /*PCデザイン横幅*/) * 100vw;
}

// visually-hidden:
@mixin visually_hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  border: 0;
}

// ブラウザハック

// Mozilla Firefox
// @include only_firefox {}
@mixin only_firefox {
  @supports (-moz-appearance: meterbar) {
    @content;
  }
}

// Safari
// @include only_safari {}
@mixin only_safari {
  @supports (paint-order: fill) and (-webkit-marquee-speed: 0) {
    @content;
  }
}

// iOS Safari
// @include only_iOS {}
@mixin only_iOS {
  @supports (-webkit-text-size-adjust: none) and (not (-ms-accelerator: true)) and (not (-moz-appearance: none)) {
    @content;
  }
}

// Microsoft Edge
// @include only_edge {}
@mixin only_edge {
  @supports (-ms-ime-align: auto) {
    @content;
  }
}

// Internet Explorer
// @include only_IE {}
@mixin only_IE {
  /* stylelint-disable-next-line selector-type-no-unknown */
  _:-ms-input-placeholder,
  :root & {
    @content;
  }
}

// @supportsを用いたIEハック
// .using {
//   IE用の記述
//   @supports (appearance: none) {
//     IE以外用の記述
//   }
// }

// @include not_IE {}
@mixin not_IE {
  @supports (appearance: none) {
    @content;
  }
}
