@charset "utf-8";

@use "../global";

.u-hideSP {
  @include global.under_md {
    display: none !important;
  }
}

.u-hidePC {
  @include global.md {
    display: none !important;
  }
}
