@charset "utf-8";

@use "../global";
@use "sass:math";

//body {
//  color: global.$color_secondary;
//  @include global.fz(15);
//  @include global.font-JP_sans;
//}
//
//* {
//  box-sizing: border-box;
//  font-family: inherit;
//  font-size: inherit;
//  line-height: inherit;
//  &::before,
//  &::after {
//    box-sizing: inherit;
//  }
//  &:focus {
//    outline: none;
//  }
//}
//
//html,
//body,
//h1,
//h2,
//h3,
//h4,
//h5,
//h6,
//a,
//p,
//span,
//em,
//small,
//strong,
//sub,
//sup,
//mark,
//del,
//ins,
//strike,
//abbr,
//dfn,
//blockquote,
//q,
//cite,
//code,
//pre,
//ol,
//ul,
//li,
//dl,
//dt,
//dd,
//div,
//section,
//article,
//main,
//aside,
//nav,
//header,
//hgroup,
//footer,
//img,
//figure,
//figcaption,
//address,
//time,
//audio,
//video,
//canvas,
//iframe,
//details,
//summary,
//fieldset,
//form,
//label,
//legend,
//table,
//caption,
//tbody,
//tfoot,
//thead,
//tr,
//th,
//td {
//  padding: 0;
//  margin: 0;
//  border: 0;
//}
//
//a {
//  outline: none;
//  -webkit-touch-callout: none;
//  &,
//  &:visited {
//    color: inherit;
//  }
//  &:hover {
//    @include global.md {
//      text-decoration: none;
//    }
//  }
//}
//
//article,
//aside,
//footer,
//header,
//nav,
//section,
//main {
//  display: block;
//}
//
//ol,
//ul {
//  list-style: none;
//}
//
//img,
//video {
//  max-width: 100%;
//}
//
//img {
//  height: auto;
//  vertical-align: top;
//  border-style: none;
//  @include global.under_md {
//    width: 100%;
//  }
//}
//
//blockquote,
//q {
//  quotes: none;
//}
//
//blockquote::after,
//blockquote::before,
//q::after,
//q::before {
//  content: none;
//}
//
//b,
//em,
//strong,
//h1,
//h2,
//h3,
//h4,
//h5,
//h6 {
//  @include global.font-JP_bold_sans;
//}
//
//address {
//  font-style: normal;
//}
//
//small {
//  font-size: 87.5%;
//}
//
//[hidden] {
//  display: none !important;
//}
//
//[disabled] {
//  cursor: not-allowed;
//}
//
///* form */
//input[type="text"],
//input[type="tel"],
//input[type="number"],
//input[type="date"],
//input[type="time"],
//input[type="password"],
//input[type="email"],
//textarea {
//  -webkit-appearance: none;
//  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
//}
//
//textarea {
//  display: block;
//}
//
//fieldset {
//  margin: 0;
//  border: none;
//}
//
//legend {
//  display: none;
//}
//
//table {
//  border-spacing: 0;
//  border-collapse: collapse;
//}

.c-button {
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 36px;
  padding: 0;
  margin-top: 10px;
  background: global.$color_white;
  border-radius: 18px;
  border: 1px solid global.$color_primary;
  color: global.$color_primary;
  @include global.md {
    height: 24px;
    padding: 0 20px !important;
    margin-top: 0;
    border-radius: 12px;
    font-size: 0.9em;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    &:hover {
      background-color: global.$color_primary;
      color: global.$color_white !important;
    }
  }
}

/* old style */
#logo {
  @include global.md {
    top: 56px;
  }
  h1 {
    @include global.under_md {
      width: 180px;
      padding-left: 0;
    }
  }
  h1 a {
    @include global.md {
      padding: 3px 4px;
      img {
        width: 220px;
        height: auto;
      }
    }
  }
}

.home #menu-search {
  @include global.md {
    top: 44px;
  }
}

.search-form {
  .search-input {
    @include global.md {
      width: 345px;
      height: 43px;
    }
  }
  .search-btn {
    background: url(/share/imgs/icon_search.svg) no-repeat center;
    background-size: 25px auto;
  }
}

#menu-search {
  top: 44px;
  z-index: 100000;
  @include global.md {
    pointer-events: none;
  }
  &::before {
    @include global.md {
      display: none;
    }
  }
  &._show {
    .search-inner {
      visibility: visible;
      opacity: 1;
      pointer-events: auto;
    }
    .c-search__btn {
      background: url(/share/imgs/icon_close.svg) no-repeat center;
      background-size: 28px auto;
    }
  }
  .inner {
    @include global.md {
      width: auto;
      height: 88px;
    }
  }
  .search-inner {
    @include global.md {
      position: absolute;
      right: 0;
      bottom: -88px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 407px;
      height: 90px;
      padding: 0;
      background: rgba(#225466, 0.77);
      visibility: hidden;
      pointer-events: none;
      opacity: 0;
      transition: visibility 0.3s ease-in-out, opacity 0.3s ease-in-out;
      &::before {
        display: none;
      }
    }
  }
  .c-search__btn {
    display: none;
    background: url(/share/imgs/icon_search.svg) no-repeat center;
    background-size: 33px auto;
    border: none;
    @include global.md {
      position: absolute;
      right: 0;
      display: block;
      width: 88px;
      height: 88px;
      transition: background 0.3s ease-in-out;
      pointer-events: auto;
      &:hover {
        opacity: 0.7;
      }
    }
    span {
      @include global.visually_hidden;
    }
  }
}

#usernav {
  .link-list {
    &.usernav {
      z-index: 1000;
      > ul {
        height: 42px;
        @include global.md {
          padding-right: 135px;
        }
        > li {
          display: inline-flex;
          align-items: center;
          @include global.md {
            height: 42px;
          }
          > a {
            width: 100%;
          }
        }
      }
    }
    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 42px;
      @include global.md {
        display: inline-flex;
      }
    }
  }
}

#usernav .link-list.link-1 {
  z-index: 1001;
  padding-right: 0;
  > ul {
    @include global.under_md {
      margin: 20px 0;
      > li {
        padding: 0;
      }
    }
  }
}

#usernav #inverse,
#usernav #fontsize {
  .menu-acc-cont {
    top: 36px;
  }
  .cont-inner {
    ul {
      background: global.$color_white;
      border: 1px solid #ccc;
    }
    ul li {
      height: auto;
    }
  }
}

#gnav {
  @include global.md {
    top: 42px;
    height: 88px;
    border-top: 1px solid #ccc;
    .home & {
      top: 42px;
    }
    &::before {
      display: none;
    }
    .menu-list.inner {
      width: 100%;
      padding-right: 90px;
      padding-left: 0;
      margin: 0 0 0 auto;
      background: global.$color_white;
    }
    ul {
      margin-left: auto;
      background: global.$color_white;
      &::before {
        display: none;
      }
      @include global.md {
        height: 88px;
      }
    }
    li {
      min-width: 110px;
      &::before {
        top: 36px;
        height: 15px;
        background: global.$color_primary;
        transform: rotate(0);
      }
      &:last-child {
        &::after {
          position: absolute;
          top: 36px;
          right: 0;
          width: 1px;
          height: 15px;
          background: global.$color_primary;
          content: "";
        }
      }
      a {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 10px 18px;
        color: global.$color_black;
      }
    }
  }
}

#usernav .link-list.link-2 {
  right: 0;
  left: auto;
  li {
    @include global.md {
      height: auto;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      overflow: hidden;
      vertical-align: middle;
    }
  }
  li + li {
    @include global.md {
      margin-top: 6px;
    }
  }
  .btn {
    @include global.md {
      display: flex;
      //height: 100%;
      border: none;
      background: none;
      font-size: 0.9em;
      -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
      white-space: nowrap;
      span {
        font-size: 1.38em;
      }
    }
  }
  .btn-1 {
    @include global.md {
      height: 114px;
    }
    .btn {
      background-color: #e03c3a;
    }
  }
  .btn-2 {
    @include global.md {
      height: 114px;
    }
    .btn {
      background-color: #6e6d9b;
    }
  }
  .btn-3 {
    @include global.md {
      height: 93px;
    }
    .btn {
      background-color: #c55d8a;
    }
  }
  .btn-4 {
    @include global.md {
      height: 157px;
    }
    .btn {
      background-color: #c77053;
    }
  }
  .btn-5 {
    @include global.md {
      height: 114px;
    }
    .btn {
      background-color: #e1a411;
    }
  }
}

/* ------ Fontsize ------ */
#usernav #fontsize {
  position: relative;
}

#usernav #fontsize a {
  display: block;
  position: relative;
  padding-right: 17px;
}

#usernav #fontsize a:before {
  transform: rotate(-45deg);
  position: absolute;
  top: 50%;
  right: 10px;
  margin-top: -6px;
  margin-top: -8px\9;
  margin-right: -5px;
  width: 8px;
  height: 8px;
  border-width: 0 0 2px 2px;
  border-style: solid;
  border-color: #0084b4;
  content: "";
}

/* IE11 */
@media all and (-ms-high-contrast:none){
  *::-ms-backdrop, #usernav #fontsize a:before {
    margin-top: -8px;
  }
}

/* Menu Accodion  */
#usernav #fontsize .menu-acc-cont {
  display: block !important;
  position: absolute;
  left: 0;
  top: 27px\9;
  width: 100%;
}

/* IE10 */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  #usernav #fontsize .menu-acc-cont {
    top: 27px;
  }
}

/* IE11 */
@media all and (-ms-high-contrast:none){
  *::-ms-backdrop, #usernav #fontsize .menu-acc-cont {
    top: 27px;
  }
}

/* Edge */
@supports (-ms-ime-align:auto) {
  #usernav #fontsize .menu-acc-cont {
    top: 30px;
  }
}

/* Menu Accodion Content Inner */
#usernav #fontsize .cont-inner {
  display: block !important;
  position: relative;
  overflow: hidden;
  transition: opacity .25s ease-in-out,height .5s ease-in-out,min-height .5s ease-in-out;
  opacity: 0;
  height: 0;
  min-height: 0;
}

#usernav #fontsize .cont-inner.show {
  display: none !important \9;
  opacity: 1;
  height: auto;
  min-height: 100px;
}

/* IE10 */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  #usernav #fontsize .cont-inner.show {
    display: block !important \9;
  }
}

#usernav #fontsize .cont-inner ul {
  padding: 8px 5px;
}

#usernav #fontsize .cont-inner ul li {
  display: block !important;
  width: auto;
  padding-top: 4px;
  &.on {
    .btn {
      background: global.$color_primary;
      color: global.$color_white;
    }
  }
}

#usernav #fontsize .cont-inner ul li:first-child {
  padding-top: 0;
}

#usernav #fontsize .btn {
  display: block;
  width: auto;
  height: 24px;
  margin: 0;
  padding: 0 5px;
  background: #fff;
  border-radius: 3px;
  border: 1px solid global.$color_primary;
  color: global.$color_primary;
  font-size: .92em;
  font-weight: bold;
  font-weight: normal\9;
  line-height: 22px;
  line-height: 25px\9;
  text-align: center;
  text-decoration: none;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  #usernav #fontsize .btn {
    line-height: 25px;
    font-weight: normal;
  }
}

#usernav #fontsize #fontsize-btn-black .btn {
  background: #000;
  color: #fff;
}

#usernav #fontsize .btn:hover,
#usernav #fontsize .btn:focus {
  opacity: .5;
}

.mega-header {
  margin-top: 68px;
  margin-bottom: 0;
  @include global.md {
    margin-top: 130px;
  }
}

#footer {
  .footer-nav.link {
    @include global.md {
      width: 1300px;
      padding: 0 50px;
    }
  }
  .inner {
    background: url(/share/imgs/img_footer_map.png) no-repeat 95% center;
    @include global.md {
      background-position: 95% 23px;
      background-size: 512px auto;
    }
  }
  .footer-logo {
    display: block;
    @include global.md {
      margin-bottom: 25px;
      a {
        display: block;
        margin-left: -14px;
      }
    }
  }
  .footer-address dl {
    @include global.md {
      width: 290px;
    }
  }
  .l-footer__map {
    padding-top: 20px;
    padding-bottom: 45px;
    img {
      width: 100%;
      height: auto;
    }
  }
}

#footer-top {
  @include global.md {
    margin-top: 0;
  }
}

#footer-bottom {
  @include global.md {
    margin-top: 130px;
  }
}

#mainvisual {
  z-index: 100;
  height: auto;
  overflow: visible;
}

.slick-dots {
  bottom: -35px;
  @include global.md {
    bottom: 60px;
  }
  li {
    button {
      width: 16px;
      height: 16px;
      border-radius: 8px;
      background: #c6c6c6;
      border: 1px solid global.$color_white;
      &::before {
        content: "";
      }
    }
    &.slick-active {
      button {
        background: #0084b4;
      }
    }
  }
}

.home #content-main {
  padding-top: 40px;
}

.l-contentsTopWrap {
  @include global.md {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    .list-sns {
      flex-shrink: 0;
      margin-top: 0;
      margin-left: 20px;
    }
  }
}

.detail .mega-header {
  height: 150px;
  background-image: url(/share/imgs/detail_mega_header.jpg);
  @include global.md {
    height: 245px;
  }
  .inner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0;
    color: global.$color_white;
  }
}

.breadcrumb .inner {
  text-align: left;
  @include global.md {
    margin-top: 10px;
  }
}
