.p-top .p-topConcept__inner.js-inview {
  opacity: 0;
  transition: opacity 1.4s cubic-bezier(0.46, 0.01, 0.59, 1), transform 1.4s cubic-bezier(0.46, 0.01, 0.59, 1);
  transform: translateY(30px);
}
.p-top .p-topConcept__inner.js-inview.-inview {
  opacity: 1;
  transition: opacity 1.4s cubic-bezier(0.46, 0.01, 0.59, 1), transform 1.4s cubic-bezier(0.46, 0.01, 0.59, 1);
  transform: translateY(0);
}
@media not all and (min-width: 769px) {
  .p-top .p-topAbout.js-inview .p-topAbout__title,
.p-top .p-topAbout.js-inview .p-topAbout__image {
    opacity: 0;
    transition: opacity 1.4s cubic-bezier(0.46, 0.01, 0.59, 1), transform 1.4s cubic-bezier(0.46, 0.01, 0.59, 1);
    transform: translateY(30px);
  }
  .p-top .p-topAbout.js-inview.-inview .p-topAbout__title,
.p-top .p-topAbout.js-inview.-inview .p-topAbout__image {
    opacity: 1;
    transition: opacity 1.4s cubic-bezier(0.46, 0.01, 0.59, 1), transform 1.4s cubic-bezier(0.46, 0.01, 0.59, 1);
    transform: translateY(0);
  }
  .p-top .p-topAbout.js-inview .p-topAbout__body.js-inview .p-topAbout__detail,
.p-top .p-topAbout.js-inview .p-topAbout__body.js-inview .c-more {
    opacity: 0;
    transition: opacity 1.4s cubic-bezier(0.46, 0.01, 0.59, 1), transform 1.4s cubic-bezier(0.46, 0.01, 0.59, 1);
    transform: translateY(30px);
  }
  .p-top .p-topAbout.js-inview .p-topAbout__body.js-inview.-inview .p-topAbout__detail,
.p-top .p-topAbout.js-inview .p-topAbout__body.js-inview.-inview .c-more {
    opacity: 1;
    transition: opacity 1.4s cubic-bezier(0.46, 0.01, 0.59, 1), transform 1.4s cubic-bezier(0.46, 0.01, 0.59, 1);
    transform: translateY(0);
  }
  .p-top .p-topAbout.js-inview .p-topAbout__body.js-inview.-inview .p-topAbout__detail:nth-of-type(1) {
    transition-delay: calc(1s + (0.5s * 1));
  }
  .p-top .p-topAbout.js-inview .p-topAbout__body.js-inview.-inview .p-topAbout__detail:nth-of-type(2) {
    transition-delay: calc(1s + (0.5s * 2));
  }
  .p-top .p-topAbout.js-inview .p-topAbout__body.js-inview.-inview .p-topAbout__detail:nth-of-type(3) {
    transition-delay: calc(1s + (0.5s * 3));
  }
  .p-top .p-topAbout.js-inview .p-topAbout__body.js-inview.-inview .c-more {
    transition-delay: calc(1s + (0.5s * 4));
  }
}
@media (min-width: 769px) {
  .p-top .p-topAbout.js-inview .p-topAbout__title,
.p-top .p-topAbout.js-inview .p-topAbout__image,
.p-top .p-topAbout.js-inview .p-topAbout__detail,
.p-top .p-topAbout.js-inview .c-more {
    opacity: 0;
    transition: opacity 1.4s cubic-bezier(0.46, 0.01, 0.59, 1), transform 1.4s cubic-bezier(0.46, 0.01, 0.59, 1);
    transform: translateY(30px);
  }
  .p-top .p-topAbout.js-inview.-inview .p-topAbout__title,
.p-top .p-topAbout.js-inview.-inview .p-topAbout__image,
.p-top .p-topAbout.js-inview.-inview .p-topAbout__detail,
.p-top .p-topAbout.js-inview.-inview .c-more {
    opacity: 1;
    transition: opacity 1.4s cubic-bezier(0.46, 0.01, 0.59, 1), transform 1.4s cubic-bezier(0.46, 0.01, 0.59, 1);
    transform: translateY(0);
  }
  .p-top .p-topAbout.js-inview.-inview .p-topAbout__title {
    transition-delay: 0.3s;
  }
  .p-top .p-topAbout.js-inview.-inview .p-topAbout__image {
    transition-delay: 1s;
  }
  .p-top .p-topAbout.js-inview.-inview .p-topAbout__detail:nth-of-type(1) {
    transition-delay: calc(1s + (0.5s * 1));
  }
  .p-top .p-topAbout.js-inview.-inview .p-topAbout__detail:nth-of-type(2) {
    transition-delay: calc(1s + (0.5s * 2));
  }
  .p-top .p-topAbout.js-inview.-inview .p-topAbout__detail:nth-of-type(3) {
    transition-delay: calc(1s + (0.5s * 3));
  }
  .p-top .p-topAbout.js-inview.-inview .c-more {
    transition-delay: calc(1s + (0.5s * 4));
  }
}
.p-top .p-topGallery .js-inview {
  opacity: 0;
  transition: opacity 1.4s cubic-bezier(0.46, 0.01, 0.59, 1), transform 1.4s cubic-bezier(0.46, 0.01, 0.59, 1);
  transform: translateY(30px);
}
.p-top .p-topGallery .js-inview.-inview {
  opacity: 1;
  transition: opacity 1.4s cubic-bezier(0.46, 0.01, 0.59, 1), transform 1.4s cubic-bezier(0.46, 0.01, 0.59, 1);
  transform: translateY(0);
}
.p-top .p-topSec._voice.js-inview {
  overflow: hidden;
}
@media not all and (min-width: 769px) {
  .p-top .p-topSec._voice.js-inview .p-topSec__image {
    opacity: 0;
    transition: opacity 1.4s cubic-bezier(0.46, 0.01, 0.59, 1), transform 1.4s cubic-bezier(0.46, 0.01, 0.59, 1);
    transform: translateX(-100px);
  }
  .p-top .p-topSec._voice.js-inview.-inview .p-topSec__image {
    opacity: 1;
    transition: opacity 1.4s cubic-bezier(0.46, 0.01, 0.59, 1), transform 1.4s cubic-bezier(0.46, 0.01, 0.59, 1);
    transform: translateX(0);
  }
  .p-top .p-topSec._voice.js-inview .p-topSec__body.js-inview .p-topSec__heading,
.p-top .p-topSec._voice.js-inview .p-topSec__body.js-inview .p-topSec__text,
.p-top .p-topSec._voice.js-inview .p-topSec__body.js-inview .c-more {
    opacity: 0;
    transition: opacity 1.4s cubic-bezier(0.46, 0.01, 0.59, 1), transform 1.4s cubic-bezier(0.46, 0.01, 0.59, 1);
    transform: translateX(-50px);
  }
  .p-top .p-topSec._voice.js-inview .p-topSec__body.js-inview.-inview .p-topSec__heading,
.p-top .p-topSec._voice.js-inview .p-topSec__body.js-inview.-inview .p-topSec__text,
.p-top .p-topSec._voice.js-inview .p-topSec__body.js-inview.-inview .c-more {
    opacity: 1;
    transition: opacity 1.4s cubic-bezier(0.46, 0.01, 0.59, 1), transform 1.4s cubic-bezier(0.46, 0.01, 0.59, 1);
    transform: translateX(0);
  }
  .p-top .p-topSec._voice.js-inview .p-topSec__body.js-inview.-inview .p-topSec__heading {
    transition-delay: 0.2s;
  }
  .p-top .p-topSec._voice.js-inview .p-topSec__body.js-inview.-inview .p-topSec__text {
    transition-delay: 0.3s;
  }
  .p-top .p-topSec._voice.js-inview .p-topSec__body.js-inview.-inview .c-more {
    transition-delay: 0.4s;
  }
}
@media (min-width: 769px) {
  .p-top .p-topSec._voice.js-inview .p-topSec__image {
    opacity: 0;
    transition: opacity 1.4s cubic-bezier(0.46, 0.01, 0.59, 1), transform 1.4s cubic-bezier(0.46, 0.01, 0.59, 1);
    transform: translateX(-100px);
  }
  .p-top .p-topSec._voice.js-inview .p-topSec__heading,
.p-top .p-topSec._voice.js-inview .p-topSec__text,
.p-top .p-topSec._voice.js-inview .c-more {
    opacity: 0;
    transition: opacity 1.4s cubic-bezier(0.46, 0.01, 0.59, 1), transform 1.4s cubic-bezier(0.46, 0.01, 0.59, 1);
    transform: translateX(-50px);
  }
  .p-top .p-topSec._voice.js-inview.-inview .p-topSec__image,
.p-top .p-topSec._voice.js-inview.-inview .p-topSec__heading,
.p-top .p-topSec._voice.js-inview.-inview .p-topSec__text,
.p-top .p-topSec._voice.js-inview.-inview .c-more {
    opacity: 1;
    transition: opacity 1.4s cubic-bezier(0.46, 0.01, 0.59, 1), transform 1.4s cubic-bezier(0.46, 0.01, 0.59, 1);
    transform: translateX(0);
  }
  .p-top .p-topSec._voice.js-inview.-inview .p-topSec__heading {
    transition-delay: 0.2s;
  }
  .p-top .p-topSec._voice.js-inview.-inview .p-topSec__text {
    transition-delay: 0.3s;
  }
  .p-top .p-topSec._voice.js-inview.-inview .c-more {
    transition-delay: 0.4s;
  }
}
.p-top .p-topSec._event.js-inview {
  overflow: hidden;
}
@media not all and (min-width: 769px) {
  .p-top .p-topSec._event.js-inview .p-topSec__image {
    opacity: 0;
    transition: opacity 1.4s cubic-bezier(0.46, 0.01, 0.59, 1), transform 1.4s cubic-bezier(0.46, 0.01, 0.59, 1);
    transform: translateX(100px);
  }
  .p-top .p-topSec._event.js-inview.-inview .p-topSec__image {
    opacity: 1;
    transition: opacity 1.4s cubic-bezier(0.46, 0.01, 0.59, 1), transform 1.4s cubic-bezier(0.46, 0.01, 0.59, 1);
    transform: translateX(0);
  }
  .p-top .p-topSec._event.js-inview .p-topSec__body.js-inview .p-topSec__heading,
.p-top .p-topSec._event.js-inview .p-topSec__body.js-inview .p-topSec__text,
.p-top .p-topSec._event.js-inview .p-topSec__body.js-inview .c-more {
    opacity: 0;
    transition: opacity 1.4s cubic-bezier(0.46, 0.01, 0.59, 1), transform 1.4s cubic-bezier(0.46, 0.01, 0.59, 1);
    transform: translateX(50px);
  }
  .p-top .p-topSec._event.js-inview .p-topSec__body.js-inview.-inview .p-topSec__heading,
.p-top .p-topSec._event.js-inview .p-topSec__body.js-inview.-inview .p-topSec__text,
.p-top .p-topSec._event.js-inview .p-topSec__body.js-inview.-inview .c-more {
    opacity: 1;
    transition: opacity 1.4s cubic-bezier(0.46, 0.01, 0.59, 1), transform 1.4s cubic-bezier(0.46, 0.01, 0.59, 1);
    transform: translateX(0);
  }
  .p-top .p-topSec._event.js-inview .p-topSec__body.js-inview.-inview .p-topSec__heading {
    transition-delay: 0.2s;
  }
  .p-top .p-topSec._event.js-inview .p-topSec__body.js-inview.-inview .p-topSec__text {
    transition-delay: 0.3s;
  }
  .p-top .p-topSec._event.js-inview .p-topSec__body.js-inview.-inview .c-more {
    transition-delay: 0.4s;
  }
}
@media (min-width: 769px) {
  .p-top .p-topSec._event.js-inview .p-topSec__image {
    opacity: 0;
    transition: opacity 1.4s cubic-bezier(0.46, 0.01, 0.59, 1), transform 1.4s cubic-bezier(0.46, 0.01, 0.59, 1);
    transform: translateX(100px);
  }
  .p-top .p-topSec._event.js-inview .p-topSec__heading,
.p-top .p-topSec._event.js-inview .p-topSec__text,
.p-top .p-topSec._event.js-inview .c-more {
    opacity: 0;
    transition: opacity 1.4s cubic-bezier(0.46, 0.01, 0.59, 1), transform 1.4s cubic-bezier(0.46, 0.01, 0.59, 1);
    transform: translateX(50px);
  }
  .p-top .p-topSec._event.js-inview.-inview .p-topSec__image,
.p-top .p-topSec._event.js-inview.-inview .p-topSec__heading,
.p-top .p-topSec._event.js-inview.-inview .p-topSec__text,
.p-top .p-topSec._event.js-inview.-inview .c-more {
    opacity: 1;
    transition: opacity 1.4s cubic-bezier(0.46, 0.01, 0.59, 1), transform 1.4s cubic-bezier(0.46, 0.01, 0.59, 1);
    transform: translateX(0);
  }
  .p-top .p-topSec._event.js-inview.-inview .p-topSec__heading {
    transition-delay: 0.2s;
  }
  .p-top .p-topSec._event.js-inview.-inview .p-topSec__text {
    transition-delay: 0.3s;
  }
  .p-top .p-topSec._event.js-inview.-inview .c-more {
    transition-delay: 0.4s;
  }
}
.p-top .p-topLinks.js-inview .p-topLinks__item {
  opacity: 0;
  transition: opacity 1.4s cubic-bezier(0.46, 0.01, 0.59, 1), transform 1.4s cubic-bezier(0.46, 0.01, 0.59, 1);
  transform: translateY(30px);
}
.p-top .p-topLinks.js-inview.-inview .p-topLinks__item {
  opacity: 1;
  transition: opacity 1.4s cubic-bezier(0.46, 0.01, 0.59, 1), transform 1.4s cubic-bezier(0.46, 0.01, 0.59, 1);
  transform: translateY(0);
}
.p-top .p-topLinks.js-inview.-inview .p-topLinks__item + .p-topLinks__item {
  transition-delay: 0.5s;
}
.p-top .p-topNews.js-inview .p-topSec__heading,
.p-top .p-topNews.js-inview .c-list {
  opacity: 0;
  transition: opacity 1.4s cubic-bezier(0.46, 0.01, 0.59, 1), transform 1.4s cubic-bezier(0.46, 0.01, 0.59, 1);
  transform: translateY(30px);
}
.p-top .p-topNews.js-inview.-inview .p-topSec__heading,
.p-top .p-topNews.js-inview.-inview .c-list {
  opacity: 1;
  transition: opacity 1.4s cubic-bezier(0.46, 0.01, 0.59, 1), transform 1.4s cubic-bezier(0.46, 0.01, 0.59, 1);
  transform: translateY(0);
}

.c-button {
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 36px;
  padding: 0;
  margin-top: 10px;
  background: #fff;
  border-radius: 18px;
  border: 1px solid #0084b4;
  color: #0084b4;
}
@media (min-width: 769px) {
  .c-button {
    height: 24px;
    padding: 0 20px !important;
    margin-top: 0;
    border-radius: 12px;
    font-size: 0.9em;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  }
  .c-button:hover {
    background-color: #0084b4;
    color: #fff !important;
  }
}

/* old style */
@media (min-width: 769px) {
  #logo {
    top: 56px;
  }
}
@media not all and (min-width: 769px) {
  #logo h1 {
    width: 180px;
    padding-left: 0;
  }
}
@media (min-width: 769px) {
  #logo h1 a {
    padding: 3px 4px;
  }
  #logo h1 a img {
    width: 220px;
    height: auto;
  }
}

@media (min-width: 769px) {
  .home #menu-search {
    top: 44px;
  }
}

@media (min-width: 769px) {
  .search-form .search-input {
    width: 345px;
    height: 43px;
  }
}
.search-form .search-btn {
  background: url(/share/imgs/icon_search.svg) no-repeat center;
  background-size: 25px auto;
}

#menu-search {
  top: 44px;
  z-index: 100000;
}
@media (min-width: 769px) {
  #menu-search {
    pointer-events: none;
  }
}
@media (min-width: 769px) {
  #menu-search::before {
    display: none;
  }
}
#menu-search._show .search-inner {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}
#menu-search._show .c-search__btn {
  background: url(/share/imgs/icon_close.svg) no-repeat center;
  background-size: 28px auto;
}
@media (min-width: 769px) {
  #menu-search .inner {
    width: auto;
    height: 88px;
  }
}
@media (min-width: 769px) {
  #menu-search .search-inner {
    position: absolute;
    right: 0;
    bottom: -88px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 407px;
    height: 90px;
    padding: 0;
    background: rgba(34, 84, 102, 0.77);
    visibility: hidden;
    pointer-events: none;
    opacity: 0;
    transition: visibility 0.3s ease-in-out, opacity 0.3s ease-in-out;
  }
  #menu-search .search-inner::before {
    display: none;
  }
}
#menu-search .c-search__btn {
  display: none;
  background: url(/share/imgs/icon_search.svg) no-repeat center;
  background-size: 33px auto;
  border: none;
}
@media (min-width: 769px) {
  #menu-search .c-search__btn {
    position: absolute;
    right: 0;
    display: block;
    width: 88px;
    height: 88px;
    transition: background 0.3s ease-in-out;
    pointer-events: auto;
  }
  #menu-search .c-search__btn:hover {
    opacity: 0.7;
  }
}
#menu-search .c-search__btn span {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  border: 0;
}

#usernav .link-list.usernav {
  z-index: 1000;
}
#usernav .link-list.usernav > ul {
  height: 42px;
}
@media (min-width: 769px) {
  #usernav .link-list.usernav > ul {
    padding-right: 135px;
  }
}
#usernav .link-list.usernav > ul > li {
  display: inline-flex;
  align-items: center;
}
@media (min-width: 769px) {
  #usernav .link-list.usernav > ul > li {
    height: 42px;
  }
}
#usernav .link-list.usernav > ul > li > a {
  width: 100%;
}
#usernav .link-list .btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
}
@media (min-width: 769px) {
  #usernav .link-list .btn {
    display: inline-flex;
  }
}

#usernav .link-list.link-1 {
  z-index: 1001;
  padding-right: 0;
}
@media not all and (min-width: 769px) {
  #usernav .link-list.link-1 > ul {
    margin: 20px 0;
  }
  #usernav .link-list.link-1 > ul > li {
    padding: 0;
  }
}

#usernav #inverse .menu-acc-cont,
#usernav #fontsize .menu-acc-cont {
  top: 36px;
}
#usernav #inverse .cont-inner ul,
#usernav #fontsize .cont-inner ul {
  background: #fff;
  border: 1px solid #ccc;
}
#usernav #inverse .cont-inner ul li,
#usernav #fontsize .cont-inner ul li {
  height: auto;
}

@media (min-width: 769px) {
  #gnav {
    top: 42px;
    height: 88px;
    border-top: 1px solid #ccc;
  }
  .home #gnav {
    top: 42px;
  }
  #gnav::before {
    display: none;
  }
  #gnav .menu-list.inner {
    width: 100%;
    padding-right: 90px;
    padding-left: 0;
    margin: 0 0 0 auto;
    background: #fff;
  }
  #gnav ul {
    margin-left: auto;
    background: #fff;
  }
  #gnav ul::before {
    display: none;
  }
}
@media (min-width: 769px) and (min-width: 769px) {
  #gnav ul {
    height: 88px;
  }
}
@media (min-width: 769px) {
  #gnav li {
    min-width: 110px;
  }
  #gnav li::before {
    top: 36px;
    height: 15px;
    background: #0084b4;
    transform: rotate(0);
  }
  #gnav li:last-child::after {
    position: absolute;
    top: 36px;
    right: 0;
    width: 1px;
    height: 15px;
    background: #0084b4;
    content: "";
  }
  #gnav li a {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 10px 18px;
    color: #000;
  }
}

#usernav .link-list.link-2 {
  right: 0;
  left: auto;
}
@media (min-width: 769px) {
  #usernav .link-list.link-2 li {
    height: auto;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    overflow: hidden;
    vertical-align: middle;
  }
}
@media (min-width: 769px) {
  #usernav .link-list.link-2 li + li {
    margin-top: 6px;
  }
}
@media (min-width: 769px) {
  #usernav .link-list.link-2 .btn {
    display: flex;
    border: none;
    background: none;
    font-size: 0.9em;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    white-space: nowrap;
  }
  #usernav .link-list.link-2 .btn span {
    font-size: 1.38em;
  }
}
@media (min-width: 769px) {
  #usernav .link-list.link-2 .btn-1 {
    height: 114px;
  }
}
#usernav .link-list.link-2 .btn-1 .btn {
  background-color: #e03c3a;
}
@media (min-width: 769px) {
  #usernav .link-list.link-2 .btn-2 {
    height: 114px;
  }
}
#usernav .link-list.link-2 .btn-2 .btn {
  background-color: #6e6d9b;
}
@media (min-width: 769px) {
  #usernav .link-list.link-2 .btn-3 {
    height: 93px;
  }
}
#usernav .link-list.link-2 .btn-3 .btn {
  background-color: #c55d8a;
}
@media (min-width: 769px) {
  #usernav .link-list.link-2 .btn-4 {
    height: 157px;
  }
}
#usernav .link-list.link-2 .btn-4 .btn {
  background-color: #c77053;
}
@media (min-width: 769px) {
  #usernav .link-list.link-2 .btn-5 {
    height: 114px;
  }
}
#usernav .link-list.link-2 .btn-5 .btn {
  background-color: #e1a411;
}

/* ------ Fontsize ------ */
#usernav #fontsize {
  position: relative;
}

#usernav #fontsize a {
  display: block;
  position: relative;
  padding-right: 17px;
}

#usernav #fontsize a:before {
  transform: rotate(-45deg);
  position: absolute;
  top: 50%;
  right: 10px;
  margin-top: -6px;
  margin-top: -8px\9 ;
  margin-right: -5px;
  width: 8px;
  height: 8px;
  border-width: 0 0 2px 2px;
  border-style: solid;
  border-color: #0084b4;
  content: "";
}

/* IE11 */
@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, #usernav #fontsize a:before {
    margin-top: -8px;
  }
}
/* Menu Accodion  */
#usernav #fontsize .menu-acc-cont {
  display: block !important;
  position: absolute;
  left: 0;
  top: 27px\9 ;
  width: 100%;
}

/* IE10 */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  #usernav #fontsize .menu-acc-cont {
    top: 27px;
  }
}
/* IE11 */
@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, #usernav #fontsize .menu-acc-cont {
    top: 27px;
  }
}
/* Edge */
@supports (-ms-ime-align: auto) {
  #usernav #fontsize .menu-acc-cont {
    top: 30px;
  }
}
/* Menu Accodion Content Inner */
#usernav #fontsize .cont-inner {
  display: block !important;
  position: relative;
  overflow: hidden;
  transition: opacity 0.25s ease-in-out, height 0.5s ease-in-out, min-height 0.5s ease-in-out;
  opacity: 0;
  height: 0;
  min-height: 0;
}

#usernav #fontsize .cont-inner.show {
  display: none !important \9 ;
  opacity: 1;
  height: auto;
  min-height: 100px;
}

/* IE10 */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  #usernav #fontsize .cont-inner.show {
    display: block !important \9 ;
  }
}
#usernav #fontsize .cont-inner ul {
  padding: 8px 5px;
}

#usernav #fontsize .cont-inner ul li {
  display: block !important;
  width: auto;
  padding-top: 4px;
}
#usernav #fontsize .cont-inner ul li.on .btn {
  background: #0084b4;
  color: #fff;
}

#usernav #fontsize .cont-inner ul li:first-child {
  padding-top: 0;
}

#usernav #fontsize .btn {
  display: block;
  width: auto;
  height: 24px;
  margin: 0;
  padding: 0 5px;
  background: #fff;
  border-radius: 3px;
  border: 1px solid #0084b4;
  color: #0084b4;
  font-size: 0.92em;
  font-weight: bold;
  font-weight: normal\9 ;
  line-height: 22px;
  line-height: 25px\9 ;
  text-align: center;
  text-decoration: none;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  #usernav #fontsize .btn {
    line-height: 25px;
    font-weight: normal;
  }
}
#usernav #fontsize #fontsize-btn-black .btn {
  background: #000;
  color: #fff;
}

#usernav #fontsize .btn:hover,
#usernav #fontsize .btn:focus {
  opacity: 0.5;
}

.mega-header {
  margin-top: 68px;
  margin-bottom: 0;
}
@media (min-width: 769px) {
  .mega-header {
    margin-top: 130px;
  }
}

@media (min-width: 769px) {
  #footer .footer-nav.link {
    width: 1300px;
    padding: 0 50px;
  }
}
#footer .inner {
  background: url(/share/imgs/img_footer_map.png) no-repeat 95% center;
}
@media (min-width: 769px) {
  #footer .inner {
    background-position: 95% 23px;
    background-size: 512px auto;
  }
}
#footer .footer-logo {
  display: block;
}
@media (min-width: 769px) {
  #footer .footer-logo {
    margin-bottom: 25px;
  }
  #footer .footer-logo a {
    display: block;
    margin-left: -14px;
  }
}
@media (min-width: 769px) {
  #footer .footer-address dl {
    width: 290px;
  }
}
#footer .l-footer__map {
  padding-top: 20px;
  padding-bottom: 45px;
}
#footer .l-footer__map img {
  width: 100%;
  height: auto;
}

@media (min-width: 769px) {
  #footer-top {
    margin-top: 0;
  }
}

@media (min-width: 769px) {
  #footer-bottom {
    margin-top: 130px;
  }
}

#mainvisual {
  z-index: 100;
  height: auto;
  overflow: visible;
}

.slick-dots {
  bottom: -35px;
}
@media (min-width: 769px) {
  .slick-dots {
    bottom: 60px;
  }
}
.slick-dots li button {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #c6c6c6;
  border: 1px solid #fff;
}
.slick-dots li button::before {
  content: "";
}
.slick-dots li.slick-active button {
  background: #0084b4;
}

.home #content-main {
  padding-top: 40px;
}

@media (min-width: 769px) {
  .l-contentsTopWrap {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  .l-contentsTopWrap .list-sns {
    flex-shrink: 0;
    margin-top: 0;
    margin-left: 20px;
  }
}

.detail .mega-header {
  height: 150px;
  background-image: url(/share/imgs/detail_mega_header.jpg);
}
@media (min-width: 769px) {
  .detail .mega-header {
    height: 245px;
  }
}
.detail .mega-header .inner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0;
  color: #fff;
}

.breadcrumb .inner {
  text-align: left;
}
@media (min-width: 769px) {
  .breadcrumb .inner {
    margin-top: 10px;
  }
}

:root {
  --zindex-header: 10;
}

.l-loading {
  z-index: calc(var(--zindex-header) + 1);
}

.l-header {
  z-index: 1000;
}

.l-footer,
.l-footer .l-footer__button,
.l-footer .l-pagetop {
  z-index: 40;
}

.p-topMainVisual__heading,
.p-topMainVisual__lead,
.p-topMainVisual__scroll {
  z-index: calc(var(--zindex-header) - 8);
}

@media not all and (min-width: 769px) {
  .u-hideSP {
    display: none !important;
  }
}

@media (min-width: 769px) {
  .u-hidePC {
    display: none !important;
  }
}