@charset "utf-8";

$sm: 640px;
$md: 769px;
$lg: 1000px;
$xl: 1280px;

$content_width: 1500px;
$content_width_narrow: 1120px;

$color_primary: #0084b4;
$color_secondary: #232323;
$color_black: #000;
$color_gray: #989898;
$color_white: #fff;
$color_bg: #f4faf6;

$ease_out_quint: cubic-bezier(0, 0.4, 0.3, 1);

$header_height: 72px;
$header_height_sp: 53px;
