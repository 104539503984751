@charset "utf-8";

@use "breakpoint";
@use "mixin";
@use "variables";

// hover
@mixin hover {
  @include breakpoint.md {
    &:hover {
      @content;
    }
  }
}

// hover opacity
@mixin hover_opacity {
  @include breakpoint.md {
    transition: opacity 0.3s variables.$ease_out_quint;
    &:hover {
      opacity: 0.7;
    }
  }
}

//hover button
@mixin hover_button($color: variables.$color_white, $base: variables.$color_primary) {
  @include breakpoint.md {
    position: relative;
    overflow: hidden;
    span {
      position: relative;
      z-index: 3;
      transition: color 0.5s variables.$ease_out_quint;
    }
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      content: "";
      background: ($base);
      transition: transform 0.5s variables.$ease_out_quint;
      transform: scale(0, 1);
      transform-origin: right top;
    }
    @include hover {
      span {
        color: ($color);
      }
      &::before {
        transform: scale(1, 1);
        transform-origin: left top;
      }
    }
  }
}

//hover 画像拡大
@mixin hover_zoom {
  @include breakpoint.md {
    position: relative;
    z-index: 1;
    overflow: hidden;
    img {
      transition: transform 0.5s variables.$ease_out_quint;
    }
    @include hover {
      img {
        transform: scale(1.05);
      }
    }
  }
}

// js-inview
@mixin js_inview {
  opacity: 0;
  transition: opacity 1.4s cubic-bezier(0.46, 0.01, 0.59, 1), transform 1.4s cubic-bezier(0.46, 0.01, 0.59, 1);
  transform: translateY(30px);
}

// inview
@mixin inview {
  opacity: 1;
  transition: opacity 1.4s cubic-bezier(0.46, 0.01, 0.59, 1), transform 1.4s cubic-bezier(0.46, 0.01, 0.59, 1);
  transform: translateY(0);
}

.p-top {
  .p-topConcept__inner.js-inview {
    @include js_inview;
    &.-inview {
      @include inview;
    }
  }
  .p-topAbout.js-inview {
    @include breakpoint.under_md {
      .p-topAbout__title,
      .p-topAbout__image {
        @include js_inview;
      }
      &.-inview {
        .p-topAbout__title,
        .p-topAbout__image {
          @include inview;
        }
      }
      .p-topAbout__body.js-inview {
        .p-topAbout__detail,
        .c-more {
          @include js_inview;
        }
        &.-inview {
          .p-topAbout__detail,
          .c-more {
            @include inview;
          }
          $aboutDelay: 0.5s;
          .p-topAbout__detail {
            @for $i from 1 through 3 {
              &:nth-of-type(#{$i}) {
                transition-delay: calc(1s + calc(#{$aboutDelay} * #{$i}));
              }
            }
          }
          .c-more {
            transition-delay: calc(1s + calc(#{$aboutDelay} * 4));
          }
        }
      }
    }
    @include breakpoint.md {
      .p-topAbout__title,
      .p-topAbout__image,
      .p-topAbout__detail,
      .c-more {
        @include js_inview;
      }
      &.-inview {
        .p-topAbout__title,
        .p-topAbout__image,
        .p-topAbout__detail,
        .c-more {
          @include inview;
        }
        .p-topAbout__title {
          transition-delay: 0.3s;
        }
        .p-topAbout__image {
          transition-delay: 1s;
        }
        $aboutDelay: 0.5s;
        .p-topAbout__detail {
          @for $i from 1 through 3 {
            &:nth-of-type(#{$i}) {
              transition-delay: calc(1s + calc(#{$aboutDelay} * #{$i}));
            }
          }
        }
        .c-more {
          transition-delay: calc(1s + calc(#{$aboutDelay} * 4));
        }
      }
    }
  }
  .p-topGallery {
    .js-inview {
      @include js_inview;
      &.-inview {
        @include inview;
      }
    }
  }
  .p-topSec._voice.js-inview {
    overflow: hidden;
    @include breakpoint.under_md {
      .p-topSec__image {
        opacity: 0;
        transition: opacity 1.4s cubic-bezier(0.46, 0.01, 0.59, 1), transform 1.4s cubic-bezier(0.46, 0.01, 0.59, 1);
        transform: translateX(-100px);
      }
      &.-inview {
        .p-topSec__image {
          opacity: 1;
          transition: opacity 1.4s cubic-bezier(0.46, 0.01, 0.59, 1), transform 1.4s cubic-bezier(0.46, 0.01, 0.59, 1);
          transform: translateX(0);
        }
      }
      .p-topSec__body.js-inview {
        .p-topSec__heading,
        .p-topSec__text,
        .c-more {
          opacity: 0;
          transition: opacity 1.4s cubic-bezier(0.46, 0.01, 0.59, 1), transform 1.4s cubic-bezier(0.46, 0.01, 0.59, 1);
          transform: translateX(-50px);
        }
        &.-inview {
          .p-topSec__heading,
          .p-topSec__text,
          .c-more {
            opacity: 1;
            transition: opacity 1.4s cubic-bezier(0.46, 0.01, 0.59, 1), transform 1.4s cubic-bezier(0.46, 0.01, 0.59, 1);
            transform: translateX(0);
          }
          .p-topSec__heading {
            transition-delay: 0.2s;
          }
          .p-topSec__text {
            transition-delay: 0.3s;
          }
          .c-more {
            transition-delay: 0.4s;
          }
        }
      }
    }
    @include breakpoint.md {
      .p-topSec__image {
        opacity: 0;
        transition: opacity 1.4s cubic-bezier(0.46, 0.01, 0.59, 1), transform 1.4s cubic-bezier(0.46, 0.01, 0.59, 1);
        transform: translateX(-100px);
      }
      .p-topSec__heading,
      .p-topSec__text,
      .c-more {
        opacity: 0;
        transition: opacity 1.4s cubic-bezier(0.46, 0.01, 0.59, 1), transform 1.4s cubic-bezier(0.46, 0.01, 0.59, 1);
        transform: translateX(-50px);
      }
      &.-inview {
        .p-topSec__image,
        .p-topSec__heading,
        .p-topSec__text,
        .c-more {
          opacity: 1;
          transition: opacity 1.4s cubic-bezier(0.46, 0.01, 0.59, 1), transform 1.4s cubic-bezier(0.46, 0.01, 0.59, 1);
          transform: translateX(0);
        }
        .p-topSec__heading {
          transition-delay: 0.2s;
        }
        .p-topSec__text {
          transition-delay: 0.3s;
        }
        .c-more {
          transition-delay: 0.4s;
        }
      }
    }
  }
  .p-topSec._event.js-inview {
    overflow: hidden;
    @include breakpoint.under_md {
      .p-topSec__image {
        opacity: 0;
        transition: opacity 1.4s cubic-bezier(0.46, 0.01, 0.59, 1), transform 1.4s cubic-bezier(0.46, 0.01, 0.59, 1);
        transform: translateX(100px);
      }
      &.-inview {
        .p-topSec__image {
          opacity: 1;
          transition: opacity 1.4s cubic-bezier(0.46, 0.01, 0.59, 1), transform 1.4s cubic-bezier(0.46, 0.01, 0.59, 1);
          transform: translateX(0);
        }
      }
      .p-topSec__body.js-inview {
        .p-topSec__heading,
        .p-topSec__text,
        .c-more {
          opacity: 0;
          transition: opacity 1.4s cubic-bezier(0.46, 0.01, 0.59, 1), transform 1.4s cubic-bezier(0.46, 0.01, 0.59, 1);
          transform: translateX(50px);
        }
        &.-inview {
          .p-topSec__heading,
          .p-topSec__text,
          .c-more {
            opacity: 1;
            transition: opacity 1.4s cubic-bezier(0.46, 0.01, 0.59, 1), transform 1.4s cubic-bezier(0.46, 0.01, 0.59, 1);
            transform: translateX(0);
          }
          .p-topSec__heading {
            transition-delay: 0.2s;
          }
          .p-topSec__text {
            transition-delay: 0.3s;
          }
          .c-more {
            transition-delay: 0.4s;
          }
        }
      }
    }
    @include breakpoint.md {
      .p-topSec__image {
        opacity: 0;
        transition: opacity 1.4s cubic-bezier(0.46, 0.01, 0.59, 1), transform 1.4s cubic-bezier(0.46, 0.01, 0.59, 1);
        transform: translateX(100px);
      }
      .p-topSec__heading,
      .p-topSec__text,
      .c-more {
        opacity: 0;
        transition: opacity 1.4s cubic-bezier(0.46, 0.01, 0.59, 1), transform 1.4s cubic-bezier(0.46, 0.01, 0.59, 1);
        transform: translateX(50px);
      }
      &.-inview {
        .p-topSec__image,
        .p-topSec__heading,
        .p-topSec__text,
        .c-more {
          opacity: 1;
          transition: opacity 1.4s cubic-bezier(0.46, 0.01, 0.59, 1), transform 1.4s cubic-bezier(0.46, 0.01, 0.59, 1);
          transform: translateX(0);
        }
        .p-topSec__heading {
          transition-delay: 0.2s;
        }
        .p-topSec__text {
          transition-delay: 0.3s;
        }
        .c-more {
          transition-delay: 0.4s;
        }
      }
    }
  }
  .p-topLinks.js-inview {
    .p-topLinks__item {
      @include js_inview;
    }
    &.-inview {
      .p-topLinks__item {
        @include inview;
        + .p-topLinks__item {
          transition-delay: 0.5s;
        }
      }
    }
  }
  .p-topNews.js-inview {
    .p-topSec__heading,
    .c-list {
      @include js_inview;
    }
    &.-inview {
      .p-topSec__heading,
      .c-list {
        @include inview;
      }
    }
  }
}
