@charset "utf-8";

:root {
  --zindex-header: 10;
}

.l-loading {
  z-index: calc(var(--zindex-header) + 1);
}

.l-header {
  z-index: 1000;
}

.l-footer,
.l-footer .l-footer__button,
.l-footer .l-pagetop {
  z-index: 40;
}

.p-topMainVisual__heading,
.p-topMainVisual__lead,
.p-topMainVisual__scroll {
  z-index: calc(var(--zindex-header) - 8);
}
